import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueAnalytics from 'vue-analytics';

const gaCode = process.env.VUE_APP_GA_CODE;

if (gaCode !== '_NONE') {
    Vue.use(VueAnalytics, {
        id: gaCode,
        router
    });
}
const prod = process.env.NODE_ENV === "production";
//const shouldSW = 'serviceWorker' in navigator && prod;
const shouldSW = "serviceWorker" in navigator;
if (shouldSW && prod) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/sw.js");
    });
}
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
