import {
  HistoryItem,
  Player,
  PlayerAndScore,
  ScoreTurnPayload,
  Turn
} from "@/scrabble";

export function getPlayersAndScores(
  players: Player[],
  turns: Turn[][]
): PlayerAndScore[] {
  return players.map((player: Player, idx: number) => {
    return {
      player,
      score: turns[idx].reduce(
        (accumulator: number, currentValue: ScoreTurnPayload) =>
          accumulator + currentValue.points,
        0
      )
    };
  });
}

export function getPlayersAndScoresForTable(p: Player[], s: Turn[][]) {
  const playersAndScores = getPlayersAndScores(p, s);
  const currentScores: HistoryItem = {};
  playersAndScores.forEach(pns => {
    currentScores[pns.player.name] = pns.score;
  });
  currentScores["round"] = "Total";
  currentScores["_rowVariant"] = "info";
  return currentScores;
}
