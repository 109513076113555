




























import { Component, Vue } from "vue-property-decorator";
import { getPlayersAndScoresForTable } from "@/util";
import { Player, Turn } from "@/scrabble";

@Component({
  components: {}
})
export default class History extends Vue {
  get games() {
    return this.$store.state.finishedGames;
  }

  getPlayersAndScoresForTable(p: Player[], s: Turn[][]) {
    return getPlayersAndScoresForTable(p, s);
  }
}
