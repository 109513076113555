var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"mt-2 col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2"},[_c('b-card',{attrs:{"border-variant":"secondary","header":'Round ' + _vm.round + ', player ' + _vm.player.name + ' is up',"header-bg-variant":"secondary","header-text-variant":"white"}},[_c('b-input-group',{staticClass:"mb-2",attrs:{"size":"sm","prepend":"Points"}},[_c('b-form-input',{ref:"scoreInput",attrs:{"autofocus":"","type":"number"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.scoreTurn()}},model:{value:(_vm.scoreForRound),callback:function ($$v) {_vm.scoreForRound=$$v},expression:"scoreForRound"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.scoreTurn()}}},[_vm._v("Done! ")])],1)],1),_c('b-table',{attrs:{"items":_vm.history,"fields":_vm.historyFields,"hover":"","striped":"","small":""},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [(
              data.field.key !== 'round' &&
                data.item.round !== 'Total' &&
                data.value
            )?_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(
              'score-modal' +
                _vm.game.id +
                '-' +
                data.item.round +
                '-' +
                data.field.key
            ),expression:"\n              'score-modal' +\n                game.id +\n                '-' +\n                data.item.round +\n                '-' +\n                data.field.key\n            "}]},[_vm._v(" "+_vm._s(data.value ? data.value : "--")+" ")]):_c('span',[_vm._v(" "+_vm._s(data.value ? data.value : "--")+" ")]),(data.field.key !== 'round' && data.item.round !== 'Total')?_c('b-modal',{attrs:{"ok-disabled":"","title":'Round ' + data.item.round + ' user ' + data.field.key,"id":'score-modal' +
                _vm.game.id +
                '-' +
                data.item.round +
                '-' +
                data.field.key}},[_c('EditScore',{attrs:{"game-id":_vm.game.id,"round":data.item.round,"user-name":data.field.key,"score":data.value},on:{"done":function($event){return _vm.closeModal(
                  'score-modal' +
                    _vm.game.id +
                    '-' +
                    data.item.round +
                    '-' +
                    data.field.key
                )}}})],1):_vm._e()]}}])}),_c('button',{staticClass:"m-1 btn btn-small btn-primary",on:{"click":function($event){return _vm.finished(_vm.game.id)}}},[_vm._v(" Finished ")]),_c('button',{staticClass:"m-1 btn btn-small btn-secondary",on:{"click":function($event){return _vm.pause()}}},[_vm._v(" Pause ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }