import { Mutations, ScrabbleState } from "@/scrabble";
import { Store } from "vuex";

export const LOCAL_STORAGE_KEY = "__scrabble_ls_key__";
export const localStorageStatePersistence = (store: Store<ScrabbleState>) => {
  store.subscribe((mutation, state) => {
    if (
      mutation.type === Mutations.SCORE_TURN ||
      mutation.type === Mutations.FINISHED_GAME ||
      mutation.type === Mutations.UPDATE_SCORE ||
      mutation.type === Mutations.DELETE_GAME ||
      mutation.type === Mutations.NEW_GAME
    ) {
      if (state.game !== null) {
        store.commit(Mutations.UPDATE_GAME_IN_HISTORY, state.game);
      }
      const stateToSave = JSON.stringify(state);
      localStorage.setItem(LOCAL_STORAGE_KEY, stateToSave);
    }
  });
};
