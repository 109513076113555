


























































































import { Component, Vue } from "vue-property-decorator";
import { Actions, NewGamePayload, Player } from "@/scrabble";
import { deepClone } from "@/store";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: {}
})
export default class NewGame extends Vue {
  data() {
    return {
      //players: [{id: '1', name: 'a', email: ''}, {id: '2', name: 'b', email: ''}, {id: '3', name: 'c', email: ''}] as Player[],
      players: [],
      player: {
        id: "",
        name: "",
        email: ""
      } as Player
    };
  }

  get fields() {
    return [
      { key: "name", sortable: false },
      { key: "action", sortable: false }
    ];
  }

  addPlayer() {
    this.$data.players.push({
      id: `${new Date().getTime()}`,
      name: this.$data.player.name,
      email: this.$data.player.email
    });
    this.$data.player.name = "";
    this.$data.player.email = "";
    (this.$refs.playerNameInput as HTMLInputElement).focus();
  }

  movePlayerUp(playerId: string) {
    const playerIdx = this._getPlayerIndex(playerId);
    if (playerIdx === 0) {
      return;
    }
    const arr = deepClone<Player[]>(this.$data.players);
    [arr[playerIdx], arr[playerIdx - 1]] = [arr[playerIdx - 1], arr[playerIdx]];
    this.$data.players = arr;
  }

  movePlayerDown(playerId: string) {
    const playerIdx = this._getPlayerIndex(playerId);
    if (playerIdx === this.$data.players.length - 1) {
      return;
    }
    const arr = deepClone<Player[]>(this.$data.players);
    [arr[playerIdx], arr[playerIdx + 1]] = [arr[playerIdx + 1], arr[playerIdx]];
    this.$data.players = arr;
  }

  editPlayer(playerId: string) {
    const playerIdx = this._getPlayerIndex(playerId);
    this.$data.player = deepClone<Player>(this.$data.players[playerIdx]);
    this.removePlayer(playerId);
  }

  removePlayer(playerId: string) {
    this.$data.players.splice(this._getPlayerIndex(playerId), 1);
  }

  startGame() {
    const game: NewGamePayload = {
      id: uuidv4(),
      gameStart: new Date().getTime(),
      gameFinished: null,
      currentTurn: 0,
      currentRound: 1,
      players: deepClone(this.$data.players),
      turns: this.$data.players.map(() => {
        return [];
      })
    };
    this.$store.dispatch(Actions.NEW_GAME, game);
    this.$router.push(`/game`);
  }

  _getPlayerIndex(playerId: string): number {
    let playerIdx = -1;
    this.$data.players.forEach((p: Player, pidx: number) => {
      if (p.id === playerId) {
        playerIdx = pidx;
      }
    });
    if (playerId == null) {
      throw `Cannot find player: ${playerId}`;
    }
    return playerIdx;
  }
}
