





























































































import { Component, Vue } from "vue-property-decorator";
import {
  Actions,
  HistoryItem,
  Player,
  PlayerAndScore,
  ScoreTurnPayload
} from "@/scrabble";
import { getPlayersAndScores } from "@/util";
import EditScore from "@/components/EditScore.vue";

@Component({
  components: {
    EditScore
  }
})
export default class Game extends Vue {
  pause() {
    this.$router.push("/");
  }

  finished(gameId: string) {
    if (confirm("Game over?")) {
      this.$store.dispatch(Actions.FINISHED_GAME, { gameId });
      this.$router.push("/");
    }
  }

  closeModal(id: string) {
    this.$bvModal.hide(id);
    setTimeout(() => (this.$refs.scoreInput as HTMLInputElement).focus(), 500);
  }

  mounted() {
    if (this.$store.state.game === null) {
      this.$router.push("/new-game");
    }
  }

  data() {
    return {
      scoreForRound: ""
    };
  }

  get game(): Game {
    return this.$store.state.game;
  }

  get player(): Player {
    return this.$store.state.game.players[this.$store.state.game.currentTurn];
  }

  get playersAndScores(): PlayerAndScore[] {
    return getPlayersAndScores(
      this.$store.state.game.players,
      this.$store.state.game.turns
    );
  }

  scoreTurn(): void {
    if (!isNaN(parseInt(this.$data.scoreForRound))) {
      this.$store.dispatch(Actions.SCORE_TURN, {
        points: parseInt(this.$data.scoreForRound)
      } as ScoreTurnPayload);
    }
    this.$data.scoreForRound = "";
    (this.$refs.scoreInput as HTMLInputElement).focus();
  }

  get round(): number {
    return this.$store.state.game.currentRound;
  }

  /*
  One field per player + the round
  */
  get historyFields() {
    const f = this.$store.state.game.players.map((p: Player) => {
      return {
        key: p.name,
        thClass: p.name === this.player.name ? "bg-warning" : ""
      };
    });
    f.splice(0, 0, { key: "round" });
    return f;
  }

  /*
  This pivots the history so it's displayable in the table, putting 0 for unplayed
  turns in the current round
   */
  get history() {
    const history: HistoryItem[] = [];

    for (let i = 0; i < this.$store.state.game.turns[0].length; i++) {
      const turnScores: HistoryItem = { round: i + 1 };
      for (let j = 0; j < this.$store.state.game.players.length; j++) {
        let playerScore = this.$store.state.game.turns[j][i];
        const player = this.$store.state.game.players[j];
        if (playerScore === undefined) {
          playerScore = 0;
        }
        turnScores[player.name] = playerScore.points;
        if (i === this.round - 1) {
          turnScores["_rowVariant"] = "success";
        }
      }
      history.push(turnScores);
    }
    const currentScores: HistoryItem = {};
    this.playersAndScores.forEach(pns => {
      currentScores[pns.player.name] = pns.score;
    });
    currentScores["round"] = "Total";
    currentScores["_rowVariant"] = "info";
    history.push(currentScores);
    return history.reverse();
  }
}
