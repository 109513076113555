import Vue from "vue";
import Vuex from "vuex";
import {
  Actions,
  Game,
  Mutations,
  NewGamePayload,
  ScoreTurnPayload,
  ScrabbleState,
  UpdateScorePayload
} from "@/scrabble";
import {
  LOCAL_STORAGE_KEY,
  localStorageStatePersistence
} from "@/store/plugins";

Vue.use(Vuex);

export function deepClone<V>(obj: V): V {
  return JSON.parse(JSON.stringify(obj));
}

export const mutations = {
  [Mutations.UPDATE_GAME_IN_HISTORY]: (state: ScrabbleState, payload: Game) => {
    let gameIndexInHistory = -1;
    state.games.forEach((game: Game, idx: number) => {
      if (game.id === payload.id) {
        gameIndexInHistory = idx;
      }
    });
    if (gameIndexInHistory === -1) {
      state.games.push(deepClone<Game>(payload));
    } else {
      state.games[gameIndexInHistory] = deepClone<Game>(payload);
    }
  },
  [Mutations.FINISHED_GAME]: (state: ScrabbleState, payload: Game) => {
    let gameIndexInHistory = -1;
    state.games.forEach((game: Game, idx: number) => {
      if (game.id === payload.id) {
        gameIndexInHistory = idx;
      }
    });
    if (gameIndexInHistory === -1) {
      throw `Cannot find game: ${payload.id}`;
    } else {
      state.finishedGames.push(deepClone<Game>(payload));
      state.games.splice(gameIndexInHistory, 1);
    }
  },
  [Mutations.DELETE_GAME]: (
    state: ScrabbleState,
    { gameId }: { gameId: string }
  ) => {
    let gameIndexInHistory = -1;
    state.games.forEach((game: Game, idx: number) => {
      if (game.id === gameId) {
        gameIndexInHistory = idx;
      }
    });
    if (gameIndexInHistory > -1) {
      state.games.splice(gameIndexInHistory, 1);
    }

    gameIndexInHistory = -1;
    state.finishedGames.forEach((game: Game, idx: number) => {
      if (game.id === gameId) {
        gameIndexInHistory = idx;
      }
    });
    if (gameIndexInHistory > -1) {
      state.finishedGames.splice(gameIndexInHistory, 1);
    }
  },
  [Mutations.NEW_GAME]: (state: ScrabbleState, payload: NewGamePayload) => {
    state.game = deepClone(payload);
  },
  [Mutations.CLEAR_GAME]: (state: ScrabbleState) => {
    state.game = null;
  },
  [Mutations.RESUME_GAME]: (state: ScrabbleState, payload: NewGamePayload) => {
    state.game = deepClone<Game>(payload);
  },
  [Mutations.UPDATE_SCORE](state: ScrabbleState, payload: UpdateScorePayload) {
    if (state.game === null || state.game?.id !== payload.gameId) {
      throw "You can only modify the active game";
    }
    const theGame = deepClone<Game>(state.game);
    let playerIndex = -1;
    state.game.players.forEach((p, pidx) => {
      if (p.name === payload.userName) {
        playerIndex = pidx;
      }
    });
    if (playerIndex === -1) {
      throw `Cannot find player ${payload.userName}`;
    }
    theGame.turns[playerIndex][payload.round - 1] = { points: payload.score };
    state.game = theGame;
  },
  [Mutations.SCORE_TURN]: (
    state: ScrabbleState,
    payload: ScoreTurnPayload
  ): void => {
    if (state.game === null) {
      throw "State game is null";
    }
    const newGame = deepClone<Game>(state.game);
    const playerCount = newGame.players.length;
    const currentTurnNumber = newGame.currentTurn; // 0 .. playerCount - 1
    const currentTurn = newGame.turns[currentTurnNumber]; // Current players list of turns

    const currentRound = newGame.currentRound; // 1 .. n until end of game
    const newTurn = currentTurn.concat({ points: payload.points });

    // Add the users round & score
    newGame.turns[currentTurnNumber] = newTurn;
    if (currentTurnNumber === playerCount - 1) {
      // Round is over
      // Move to next round and first user
      newGame.currentRound = currentRound + 1;
      newGame.currentTurn = 0;
    } else {
      // Next user's turn
      newGame.currentTurn = currentTurnNumber + 1;
    }
    state.game = newGame;
  }
};

function getInitialState(): ScrabbleState {
  try {
    const loadedStateString = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (loadedStateString !== null) {
      const loadedState = JSON.parse(loadedStateString) as ScrabbleState;
      return loadedState;
    }
  } catch (e) {
    // TODO
  }
  return { game: null, games: [], finishedGames: [] };
}

export default new Vuex.Store<ScrabbleState>({
  state: getInitialState(),
  mutations,
  actions: {
    [Actions.UPDATE_SCORE](context, payload: UpdateScorePayload) {
      context.commit(Mutations.UPDATE_SCORE, payload);
    },
    [Actions.SCORE_TURN](context, payload: ScoreTurnPayload) {
      context.commit(Mutations.SCORE_TURN, payload);
    },
    [Actions.NEW_GAME](context, payload: NewGamePayload) {
      context.commit(Mutations.NEW_GAME, payload);
    },
    [Actions.RESUME_GAME](context, payload: { gameId: string }) {
      let found = false;
      if (this.state.game?.id === payload.gameId) {
        // NoOp - game is active
      }
      this.state.games.forEach((game: Game) => {
        if (game.id === payload.gameId) {
          context.commit(Mutations.RESUME_GAME, game);
          found = true;
        }
      });
      if (!found) {
        throw `Cannot find saved game for ${payload.gameId}`;
      }
    },
    [Actions.FINISHED_GAME](context, payload: { gameId: string }) {
      let found = false;
      // Clear the game if we're playing it
      if (this.state.game !== null && this.state.game.id === payload.gameId) {
        context.commit(Mutations.CLEAR_GAME);
      }
      // Find it in the history & finish -> move to other list
      this.state.games.forEach((game: Game) => {
        if (game.id === payload.gameId) {
          context.commit(Mutations.FINISHED_GAME, {
            ...game,
            gameFinished: new Date().getTime()
          });
          found = true;
        }
      });
      if (!found) {
        throw `Cannot find saved game for ${payload.gameId}`;
      }
    },
    [Actions.DELETE_GAME](context, payload: { gameId: string }) {
      // Clear the game if we're playing it
      if (this.state.game?.id === payload.gameId) {
        context.commit(Mutations.CLEAR_GAME);
      }
      context.commit(Mutations.DELETE_GAME, payload);
    }
  },
  modules: {},
  plugins: [localStorageStatePersistence]
});
