


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Actions } from "@/scrabble";

@Component({})
export default class EditScore extends Vue {
  @Prop(String) private gameId!: string;
  @Prop(Number) private round!: number;
  @Prop(String) private userName!: string;
  @Prop({ type: Number, default: 0 }) private score!: number;

  data() {
    return {
      scoreForRound: parseInt(this.$props.score)
    };
  }

  changeScore() {
    if (!isNaN(parseInt(this.$data.scoreForRound))) {
      const payload = {
        gameId: this.$props.gameId,
        round: this.$props.round,
        userName: this.$props.userName,
        score: parseInt(this.$data.scoreForRound)
      };
      this.$store.dispatch(Actions.UPDATE_SCORE, payload);
    }
    this.$emit("done");
  }
}
