import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import NewGame from "@/views/NewGame.vue";
import Game from "@/views/Game.vue";
import History from "@/views/History.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/new-game",
    name: "New Game",
    component: NewGame
  },
  {
    path: "/load-game/:id",
    name: "Load Game"
    /*
        Load into state this.$route.params.id
        redirect to /game
         */
  },
  {
    path: "/game",
    name: "Game",
    component: Game
  },
  {
    path: "/history",
    name: "History",
    component: History
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
