





































































import { Component, Vue } from "vue-property-decorator";
import { getPlayersAndScoresForTable } from "@/util";
import { Actions, Player, Turn } from "@/scrabble";

@Component({
  components: {}
})
export default class Home extends Vue {
  finished(gameId: string) {
    this.$store.dispatch(Actions.FINISHED_GAME, { gameId });
  }

  deleteGame(gameId: string) {
    this.$store.dispatch(Actions.DELETE_GAME, { gameId });
  }

  resume(gameId: string) {
    this.$store.dispatch(Actions.RESUME_GAME, { gameId });
    this.$router.push("/game");
  }

  getPlayersAndScoresForTable(p: Player[], s: Turn[][]) {
    return getPlayersAndScoresForTable(p, s);
  }

  get finishedGames() {
    return this.$store.state.finishedGames;
  }

  get unfinishedGames() {
    return this.$store.state.games;
  }
}
