export interface Turn {
  points: number;
}

export interface Player {
  id: string;
  name: string;
  email: string | null;
}

export interface Game {
  id: string;
  gameStart: number | null;
  gameFinished: number | null;
  currentTurn: number;
  currentRound: number;
  players: Player[];
  turns: Turn[][];
}

export interface PlayerAndScore {
  player: Player;
  score: number;
}

export interface ScrabbleState {
  game: Game | null;
  games: Game[];
  finishedGames: Game[];
}

export enum Actions {
  NEW_GAME = "ACTION_NEW_GAME",
  RESUME_GAME = "ACTION_RESUME_GAME",
  DELETE_GAME = "ACTION_DELETE_GAME",
  FINISHED_GAME = "ACTION_FINISHED_GAME",
  SCORE_TURN = "ACTION_SCORE_TURN",
  UPDATE_SCORE = "ACTION_UPDATE_SCORE"
}

export enum Mutations {
  NEW_GAME = "MUTATION_NEW_GAME",
  RESUME_GAME = "MUTATION_RESUME_GAME",
  DELETE_GAME = "MUTATION_DELETE_GAME",
  FINISHED_GAME = "MUTATION_FINISHED_GAME",
  SCORE_TURN = "MUTATION_SCORE_TURN",
  UPDATE_GAME_IN_HISTORY = "MUTATION_UPDATE_GAME_IN_HISTORY",
  CLEAR_GAME = "MUTATION_CLEAR_GAME",
  UPDATE_SCORE = "MUTATION_UPDATE_SCORE"
}

export interface UpdateScorePayload {
  gameId: string;
  round: number;
  userName: string;
  score: number;
}

export interface ScoreTurnPayload {
  points: number;
}

export type HistoryItem = {
  [key: string]: number | string;
};

export type NewGamePayload = Game;
